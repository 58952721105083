/**
 * Меняю форматирование даты на свой кастомный язык
 * @param dateFormat {Object}
 * @param i18n {Object}
 */
export function getMyDateFormat(dateFormat, i18n) {
  i18n.dayNames = [
    "Вс",
    "Пн",
    "Вт",
    "Ср",
    "Чт",
    "Пт",
    "Сб",
    "Воскресенье",
    "Понедельник",
    "Вторник",
    "Среда",
    "Четверг",
    "Пятница",
    "Суббота",
  ];

  i18n.monthNames = [
    "Янв",
    "Фев",
    "Мар",
    "Апр",
    "Май",
    "Июн",
    "Июл",
    "Авг",
    "Сен",
    "Окт",
    "Ноя",
    "Дек",
    "Января",
    "Февраля",
    "Марта",
    "Апреля",
    "Мая",
    "Июня",
    "Июля",
    "Августа",
    "Сентября",
    "Октября",
    "Ноября",
    "Декабря",
  ];

  i18n.timeNames = ["a", "p", "до полудня", "после полудня", "A", "P", "AM", "PM"];
}
